[data-filepond-item-state*='load-invalid']
  .filepond--file-status
  .filepond--file-status-sub {
  display: none;
}

[data-filepond-item-state*='load-invalid'] .filepond--file-status {
  align-self: center;
}

[data-filepond-item-state*='load-invalid'] .filepond--item-panel {
  background-color: #369763;
}

.filepond--root {
  max-height: 30em;
}

.filepond--drop-label.filepond--drop-label label {
  cursor: pointer;
}

.filepond--panel-root {
  background-color: #d2d2d2;
}

.filepond--credits {
  display: none;
}
