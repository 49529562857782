html {
  font-size: 14px;
  text-rendering: optimizeLegibility;
}

a:link {
  margin: 0;
  color: #00a3e0;
  text-decoration-color: rgba(0, 163, 224, 0.4);
  font-size: 1rem;
  font-family: 'gotham', 'proxima-nova', 'Roboto', sans-serif;
}

.caption {
  font-size: 12px !important;
  font-weight: 400;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.headline {
  font-size: 24px !important;
  line-height: 32px !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.black--text {
  color: #000 !important;
  caret-color: #000 !important;
  text-decoration-color: #000 !important;
}

.grey--text {
  color: rgba(0, 0, 0, 0.26) !important;
  caret-color: rgba(0, 0, 0, 0.26) !important;
}

.white--text {
  color: #fff !important;
  caret-color: #fff !important;
}

.red--text {
  color: #f44336 !important;
  caret-color: #f44336 !important;
}

.red--text.text--darken-2 {
  color: #d32f2f !important;
  caret-color: #d32f2f !important;
}

.red--text.text--darken-3 {
  color: #c62828 !important;
  caret-color: #c62828 !important;
}

.yellow--text {
  color: #ffeb3b !important;
  caret-color: #ffeb3b !important;
}

.yellow--text.text--darken-2 {
  color: #fbc02d !important;
  caret-color: #fbc02d !important;
}

.yellow--text.text--darken-3 {
  color: #fbc02d !important;
  caret-color: #fbc02d !important;
}

.purple--text {
  color: #9c27b0 !important;
  caret-color: #9c27b0 !important;
}

.purple--text.text--darken-2 {
  color: #7b1fa2 !important;
  caret-color: #7b1fa2 !important;
}

.purple--text.text--darken-3 {
  color: #6a1b9a !important;
  caret-color: #6a1b9a !important;
}

.opacityOn {
  -webkit-transition: opacity 0.17s ease-in-out;
  transition: opacity 0.17s ease-in-out;
  opacity: 0.3 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.line-height-2--children * {
  line-height: 2 !important;
}

.btn-style {
  width: 200px !important;
  height: 44px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-transform: capitalize;
  text-decoration-line: none !important;
}

.rdrDefinedRangesWrapper {
  width: 8rem;
  text-align: center;
}

.rdrInputRanges {
  display: none;
}

@media screen and (max-width: 599px) {
  .btn-style {
    width: 95% !important;
  }
}

.signature {
  width: 100%;
  height: 100%;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
  .rccs {
    transform: scale(0.75);
    transition: transform ease-in-out;
  }
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
